import React from "react";
import { useFormContext } from "react-hook-form";
import { SubmissionsBuilderFormDataStructure } from "./types";
import { FlexRowStyled } from "./SidebarColumn/__styles__/HeaderMiddleContainer";
import { Tag } from "../../../../Common/Tag";
import {
  submissionIntakeSourceToCreatorType,
  submissionHiddenFromPublicByDefaultToViewerType,
} from "./utils";
import { creatorTypeLabel, viewerTypeLabel } from "./constants";

export const HeaderMiddleContainer = () => {
  const { watch } = useFormContext<SubmissionsBuilderFormDataStructure>();
  const intakeSource = watch("intakeSource");
  const hiddenFromPublicByDefault = watch("hiddenFromPublicByDefault");

  const creatorType = submissionIntakeSourceToCreatorType(intakeSource);
  const viewerType = submissionHiddenFromPublicByDefaultToViewerType(
    hiddenFromPublicByDefault
  );

  return (
    <FlexRowStyled>
      {creatorType && (
        <Tag styleVariant="neutral" leftIconName="clipboard-pen">
          {creatorTypeLabel[creatorType]}
        </Tag>
      )}
      {viewerType && (
        <Tag styleVariant="neutral" leftIconName="eye">
          {viewerTypeLabel[viewerType]}
        </Tag>
      )}
    </FlexRowStyled>
  );
};
