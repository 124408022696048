import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import {
  SELECTED_LANGUAGE_KEY,
  updateSelectedLanguage,
} from "../../utils/i18n/utils";
import { ExtendedApolloClient } from "../../apollo";
import { isNotNull } from "common/utils/tools";
import {
  SUBMISSION_TABLE_TO_CATEGORY,
  SUPPORTED_LANGUAGES,
} from "common/constants";
import { buildLink } from "common/routing";
import { accountTitle, useIsMobileWidth, useIsTabletWidth } from "./utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { AuthContext } from "../Authorization/AuthContext";
import { useOutsideAlerter } from "../../utils/outsideAlerter";
import { DropdownMenu } from "../Inputs";
import { Button } from "../Common/Button";
import { ActionsProps, DropdownOption } from "../Inputs/DropdownMenu";
import { getCurrentURL } from "../../utils/url";
import { Icon } from "../Common/Icons/LucideIcons";
import { Body } from "../Common/Typography";
import AITranslationWarningBanner from "../Common/AITranslationWarningBanner";

import {
  AccountLogo,
  FooterContainer,
  HeaderContainer,
  HeaderContent,
  MenuSection,
  MenuParent,
  Page,
  HomeButton,
  LanguageButtonWrapper,
  MenuRow,
  GUEST_WIDTH,
} from "./__styles__/Layout";
import { groupBy, isEmpty, upperCase } from "lodash";
import { SavedView } from "../../generated/graphql";
import {
  isSubmissionTable,
  TABLE_NAMES,
  TopLevelTableNames,
} from "common/utils/queryBuilder";
import { SUBMISSION_CATEGORY_TO_LABEL } from "common/utils/submissions";

const LANGUAGE_MAPPER = {
  en: "English",
  es: "Español",
};

const LanguageButton = ({ inMenu = false }: { inMenu?: boolean }) => {
  const { i18n } = useTranslation();
  const { setValue } = useLocalStorage({ key: SELECTED_LANGUAGE_KEY });
  const client = useApolloClient() as ExtendedApolloClient;
  const account = useContext(AuthContext).account!;
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);

  const supportedLanguages = account.publicPortal.supportedLanguages!;
  if (supportedLanguages!.length === 1) return null;

  const languageActions = supportedLanguages
    .map(language => {
      if (language === i18n.language) return null;
      return {
        label: LANGUAGE_MAPPER[language],
        onClick: () =>
          updateSelectedLanguage({
            language,
            updateStaticTranslations: i18n.changeLanguage,
            updateDynamicTranslations: client.updateLanguage,
            updateLocalStorage: setValue,
          }),
      };
    })
    .filter(isNotNull);

  const selectedLanguageCopy =
    LANGUAGE_MAPPER[i18n.language as SUPPORTED_LANGUAGES];

  return inMenu ? (
    <MenuSection>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={() => setLanguageMenuOpen(!languageMenuOpen)}
      >
        <Button
          onClick={() => setLanguageMenuOpen(!languageMenuOpen)}
          size="medium"
          leftIconName="globe"
          styleVariant="buttonLinkDark"
        >
          {selectedLanguageCopy}
        </Button>
        <Icon
          iconName={languageMenuOpen ? "chevron-up" : "chevron-down"}
          size={16}
          color="contentPrimaryDark"
        />
      </div>
      {languageMenuOpen &&
        languageActions.map((action, index) => (
          <Button
            key={index}
            onClick={action.onClick!}
            styleVariant="buttonLinkDark"
            size="medium"
            style={{ paddingLeft: "24px" }}
          >
            {action.label}
          </Button>
        ))}
    </MenuSection>
  ) : (
    <LanguageButtonWrapper>
      <DropdownMenu
        actions={languageActions}
        customButton={({ onClick }) => (
          <Button
            onClick={onClick}
            size="medium"
            leftIconName="globe"
            rightIconName="chevron-down"
            styleVariant="buttonLinkDark"
          >
            {selectedLanguageCopy}
          </Button>
        )}
        position="below-right"
      />
    </LanguageButtonWrapper>
  );
};

const AccessRecordsButton = ({ inMenu = false }: { inMenu?: boolean }) => {
  const { t } = useTranslation();
  const { account, supportsElevationCertificates } = useContext(AuthContext);
  const [isInMenuDropdownOpen, setIsInMenuDropdownOpen] = useState(false);

  const viewsByTableName: Partial<
    Record<
      TopLevelTableNames,
      Array<Pick<SavedView, "id" | "name"> | { name: string }>
    >
  > = groupBy(account?.savedViews, savedView => savedView.query.table);

  if (supportsElevationCertificates) {
    viewsByTableName[TABLE_NAMES.PERMITS] ??= [];

    viewsByTableName[TABLE_NAMES.PERMITS].unshift({
      name: t("button-elevation-certificates"),
    });
  }

  // Ensure consistent ordering
  const sortedViewsByTableName = Object.entries(viewsByTableName).sort((a, b) =>
    a[0].localeCompare(b[0])
  );

  const actions: Array<ActionsProps> = [];

  for (const [tableName, views] of sortedViewsByTableName) {
    let label = tableName;

    if (isSubmissionTable(tableName)) {
      const category = SUBMISSION_TABLE_TO_CATEGORY[tableName];
      if (category) {
        label = SUBMISSION_CATEGORY_TO_LABEL[category];
      }
    }

    actions.push({
      label: upperCase(label),
      onClick: () => {},
      variant: inMenu ? "sectionHeader" : "sectionHeaderDark",
      lineWrap: true,
    });

    views.forEach(view => {
      if ("id" in view) {
        actions.push({
          label: view.name,
          href: buildLink("residentSavedView", { savedViewId: view.id }),
          lineWrap: true,
          variant: inMenu ? "contentDark" : undefined,
        });
      } else {
        actions.push({
          label: view.name,
          href: buildLink("guestElevationCertificates", {
            accountId: account?.id,
          }),
          lineWrap: true,
          variant: inMenu ? "contentDark" : undefined,
        });
      }
    });
  }

  if (isEmpty(actions)) return null;

  if (inMenu) {
    return (
      <>
        <Button
          styleVariant="buttonLinkDark"
          size="medium"
          onClick={() => setIsInMenuDropdownOpen(!isInMenuDropdownOpen)}
          rightIconName={isInMenuDropdownOpen ? "chevron-up" : "chevron-down"}
        >
          {t("access-records")}
        </Button>
        {isInMenuDropdownOpen && (
          <div
            style={{
              margin: "-16px",
              // If there are many views, we need to enable scrolling.
              // The px offset is for header + some extra space on the bottom
              maxHeight: "calc(100vh - 300px)",
              overflowY: "auto",
            }}
          >
            {actions
              .filter(action => !action.hidden)
              .map((action, i) => {
                return <DropdownOption action={action} key={i} />;
              })}
          </div>
        )}
      </>
    );
  }

  return (
    <DropdownMenu
      actions={actions}
      actionListWidth="360px"
      customButton={({ onClick }) => (
        <Button
          styleVariant="buttonLinkDark"
          size="medium"
          onClick={onClick}
          rightIconName="chevron-down"
        >
          {t("access-records")}
        </Button>
      )}
      position="below-left"
    />
  );
};

const SubmitButton = ({ inMenu = false }: { inMenu?: boolean }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [isInMenuDropdownOpen, setIsInMenuDropdownOpen] = useState(false);
  const { account } = useContext(AuthContext);
  const supportElevationCertificateUploads =
    account?.publicPortal.supportsDocumentUploads;

  const actions: Array<ActionsProps> = [];

  if (supportElevationCertificateUploads) {
    actions.push({
      label: t("dropdown-upload-elevation-certificates"),
      onClick: () =>
        history.push(
          buildLink("guestUploadElevationCertificates", {
            accountId: account.id,
          }),
          { prevLocation: getCurrentURL(location) }
        ),
      lineWrap: true,
      variant: inMenu ? "contentDark" : undefined,
    });
  }

  if (isEmpty(actions)) return null;

  if (inMenu) {
    return (
      <>
        <Button
          styleVariant="buttonLinkDark"
          size="medium"
          onClick={() => setIsInMenuDropdownOpen(!isInMenuDropdownOpen)}
          rightIconName={isInMenuDropdownOpen ? "chevron-up" : "chevron-down"}
        >
          {t("submit")}
        </Button>
        {isInMenuDropdownOpen && (
          <div style={{ margin: "-16px" }}>
            {actions
              .filter(action => !action.hidden)
              .map((action, i) => {
                return <DropdownOption action={action} key={i} />;
              })}
          </div>
        )}
      </>
    );
  }

  return (
    <DropdownMenu
      actions={actions}
      actionListWidth="360px"
      customButton={({ onClick }) => (
        <Button
          styleVariant="buttonLinkDark"
          size="medium"
          onClick={onClick}
          rightIconName="chevron-down"
        >
          {t("submit")}
        </Button>
      )}
      position="below-left"
    />
  );
};

const GetHelpButton = ({
  inMenu = false,
  propertyId,
}: {
  inMenu?: boolean;
  propertyId?: string;
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const account = useContext(AuthContext).account!;

  const onGetHelpClick = () =>
    history.push(
      buildLink("getHelp", { accountId: account.id }, { propertyId })
    );

  const style = inMenu
    ? undefined
    : { minWidth: "unset", whiteSpace: "nowrap" as const };

  return (
    <Button
      onClick={onGetHelpClick}
      styleVariant={inMenu ? "buttonLinkDark" : "secondary"}
      size="medium"
      leftIconName={inMenu ? undefined : "info"}
      style={style}
    >
      {t("get-help")}
    </Button>
  );
};

const HeaderRowMenu = ({ propertyId }: { propertyId?: string }) => {
  return (
    <>
      <MenuRow>
        <AccessRecordsButton />
        <SubmitButton />
        <GetHelpButton propertyId={propertyId} />
      </MenuRow>
      <LanguageButton />
    </>
  );
};

const ExpandedMenu = ({ propertyId }: { propertyId?: string }) => {
  const account = useContext(AuthContext).account!;

  return (
    <MenuParent style={{ background: account.publicPortal.color }}>
      <MenuSection>
        <AccessRecordsButton inMenu />
        <SubmitButton inMenu />
        <GetHelpButton inMenu propertyId={propertyId} />
      </MenuSection>
      <LanguageButton inMenu />
    </MenuParent>
  );
};

const MenuButton = ({ onClick }: { onClick: () => void }) => (
  <Icon
    iconName="align-justify"
    onClick={onClick}
    size={16}
    color="contentPrimaryDark"
    testId="menu-button"
  />
);

interface HeaderProps {
  propertyId?: string;
}

export const Header = ({ propertyId }: HeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const wrapperRef = useRef(null);
  const account = useContext(AuthContext).account!;
  const isMobileScreen = useIsMobileWidth();
  const isTabletScreen = useIsTabletWidth();

  const isSmallScreen = isMobileScreen || isTabletScreen;

  useEffect(() => {
    setMenuOpen(false);
  }, [isSmallScreen]);

  // when someone clicks outside of the mobile menu the menu will disappear
  useOutsideAlerter({
    ref: wrapperRef,
    onOutsideInteraction: () => setMenuOpen(false),
  });

  const subtitle = account.publicPortal.navBarTitle;

  return (
    <>
      <HeaderContainer
        style={{ background: account.publicPortal.color }}
        ref={wrapperRef}
        id="header"
      >
        <HeaderContent>
          <HomeButton to={buildLink("guestHome", { accountId: account.id })}>
            {account.logoUrl && (
              <AccountLogo src={account.logoUrl} alt={`${account.name} Logo`} />
            )}
            <div>
              <Body
                testId="resident-main-header"
                size="large"
                type="emphasis"
                color="contentPrimaryDark"
              >
                {accountTitle()}
              </Body>
              <Body
                testId="resident-subheader"
                size="small"
                type="regular"
                color="contentPrimaryDark"
              >
                {subtitle}
              </Body>
            </div>
          </HomeButton>
          {isSmallScreen ? (
            <MenuButton onClick={() => setMenuOpen(!menuOpen)} />
          ) : (
            <HeaderRowMenu propertyId={propertyId} />
          )}
        </HeaderContent>
        {menuOpen && <ExpandedMenu propertyId={propertyId} />}
      </HeaderContainer>
      <AITranslationWarningBanner />
    </>
  );
};

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterContainer id="footer">
      <Body size="default" type="regular" color="contentPlaceholder">
        <a href="https://www.withforerunner.com/" target="_blank">
          {t("footer-forerunner")}
        </a>{" "}
        |{" "}
        <a
          href="https://www.withforerunner.com/legal/terms-of-services"
          target="_blank"
        >
          {t("footer-terms")}
        </a>
      </Body>
    </FooterContainer>
  );
};

type LayoutProps = {
  propertyId?: string;
  hasGutters?: boolean;
  children: ReactNode;
};

const Layout = ({ propertyId, children, hasGutters = false }: LayoutProps) => {
  const childrenDisplay = hasGutters ? (
    <div
      style={{ maxWidth: GUEST_WIDTH, marginLeft: "auto", marginRight: "auto" }}
    >
      {children}
    </div>
  ) : (
    children
  );
  return (
    <Page>
      <Header propertyId={propertyId} />
      {childrenDisplay}
      <Footer />
    </Page>
  );
};

export default Layout;
