import React, { useContext, useEffect, useState } from "react";

import { checkForSavedViewsBreakingChanges } from "common/utils/submissions";
import { FormProvider, useForm } from "react-hook-form";
import FullPageFormLayout from "../../../../Common/FullPageFormLayout";
import {
  SubmissionsBuilderButtonsWrapper,
  SubmissionsBuilderWrapper,
} from "./__styles__/SubmissionsBuilder";
import { MainColumn } from "./MainColumn/MainColumn";
import { SidebarColumn } from "./SidebarColumn/SidebarColumn";
import { SubmissionsBuilderContextInstance } from "./context";
import { SubmissionsBuilderFormDataStructure } from "./types";
import useSubmissionsBuilderContextManager from "./useSubmissionsBuilderContextManager";
import { useHistory, useLocation, useParams } from "react-router";
import {
  GetSubmissionTypeForBuilderQuery,
  useCreateSubmissionTypeMutation,
  useGetSubmissionsSavedViewsQuery,
  useGetSubmissionTypeForBuilderQuery,
  useUpdateSubmissionTypeMutation,
} from "../../../../../generated/graphql";
import { buildFormStructure } from "common/services/formBuilderService";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";
import { isEmpty, omit } from "lodash";
import {
  CreateSubmissionTypeFormStructure,
  useCreateSubmissionTypeModal,
} from "./createSubmissionTypeModal";
import { ApolloError } from "@apollo/client";
import { buildLink } from "common/routing";
import { getUserErrors } from "common-client/utils/apollo";
import resolver from "./formResolver";
import { AuthContext } from "../../../../Authorization/AuthContext";
import { captureException } from "@sentry/browser";
import {
  buildNotificationEmailsArray,
  generateDefaultValues,
  getValidPostambleForIntakeSource,
  getValidPreambleForIntakeSource,
} from "./utils";
import { INTAKE_SOURCE } from "common/constants";
import SupportedMobileVersionsContainer from "./SupportedMobileVersionsContainer";
import { DEFAULT_POSTAMBLE, DEFAULT_PREAMBLE } from "./constants";
import { Icon } from "../../../../Common/Icons/LucideIcons";
import { RightSidebarColumn } from "./RightSidebarColumn";
import { Button } from "../../../../Common/Button";
import { HeaderMiddleContainer } from "./HeaderMiddleContainer";

const SubmissionsBuilder = ({
  existingSubmissionType,
}: {
  existingSubmissionType?: GetSubmissionTypeForBuilderQuery["submissionType"];
}) => {
  const history = useHistory();
  const { addErrorToast, addSuccessToast } = useStatusToasts();
  const { account } = useContext(AuthContext);
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const { data: submissionsSavedViewsData } = useGetSubmissionsSavedViewsQuery({
    variables: { accountId: account!.id },
    skip: !existingSubmissionType,
    fetchPolicy: "cache-and-network",
  });

  const submissionsSavedViews =
    submissionsSavedViewsData?.account?.submissionsSavedViews;

  const location = useLocation<undefined | { prevLocation?: string }>();
  const prevLocation =
    location.state?.prevLocation ?? "/settings/account/records";

  const context = useSubmissionsBuilderContextManager({
    submissionTypeVersions: existingSubmissionType?.versions,
    readOnly: showRightSidebar,
    currentVersionId: existingSubmissionType?.currentVersion.id,
  });

  const defaultValues = generateDefaultValues({
    existingSubmissionType,
    versionId: context.selectedVersionId,
  });

  const formMethods = useForm<SubmissionsBuilderFormDataStructure>({
    // @ts-ignore -- until we get all other input types finished
    defaultValues,
    context,
    resolver,
    mode: "onChange",
  });

  useEffect(() => {
    // @ts-expect-error -- If it's a new form, Attachments could be an empty arry,
    // which doesn't match the type for the form.
    formMethods.reset(defaultValues);
  }, [context.selectedVersionId]);

  const { isValid, isDirty } = formMethods.formState;
  const { handleSubmit, setValue, watch } = formMethods;

  const onCompleted = (verb: "created" | "updated") => {
    addSuccessToast(`Record type ${verb}`);
    history.push(buildLink("records"));
  };

  const onError = (error: ApolloError) => {
    const userErrors = getUserErrors(error);

    if (userErrors) {
      addErrorToast(userErrors);
    } else {
      addErrorToast("Could not save record type. Please try again.");
    }
  };

  const [createSubmissionType] = useCreateSubmissionTypeMutation({
    onCompleted: () => onCompleted("created"),
    onError,
  });

  const [updateSubmissionType] = useUpdateSubmissionTypeMutation({
    onCompleted: () => onCompleted("updated"),
    onError,
  });

  const [showCreateSubmissionTypeModal] = useCreateSubmissionTypeModal({
    context,
    onSubmit: ({
      name,
      category,
      intakeSource,
      attachments,
    }: CreateSubmissionTypeFormStructure) => {
      setValue("name", name);
      setValue("category", category);
      setValue("intakeSource", intakeSource);
      setValue("attachments", attachments);
      if (intakeSource === INTAKE_SOURCE.INTERNAL) {
        setValue("preamble", null);
        setValue("postamble", null);
        setValue("notificationEmails", null);
        setValue("successMessage", `Your ${name} has been saved`);
      } else {
        setValue("postamble", DEFAULT_POSTAMBLE);
        setValue("preamble", DEFAULT_PREAMBLE);
        setValue("successMessage", null);
      }
    },
  });

  useEffect(() => {
    if (!existingSubmissionType) {
      showCreateSubmissionTypeModal();
    }
  }, []);

  const formName = watch("name");

  const subtitle = formName ? `${formName} form` : "Add record type";

  const handleSubmitCallback = async (
    data: SubmissionsBuilderFormDataStructure
  ) => {
    const formStructure = buildFormStructure({ formData: data.inputs! });

    if (!formStructure.isValid) {
      let errorMessage = existingSubmissionType
        ? `formStructure for existing submission type (id=${existingSubmissionType.id})`
        : "formStructure";

      errorMessage += `is invalid. Please address the following errors:\n${formStructure.errors.join(
        "\n"
      )}`;

      throw new Error(errorMessage);
    }

    if (existingSubmissionType) {
      const savedViewBreakingChanges = checkForSavedViewsBreakingChanges({
        submissionTypeId: existingSubmissionType.id,
        submissionTypeName: existingSubmissionType.name,
        previousFormStructure: existingSubmissionType.versions.find(
          version => version.id === context.selectedVersionId
        )!.formStructure,
        currentFormStructure: formStructure,
        submissionSavedViews: submissionsSavedViews ?? [],
        category: existingSubmissionType.category,
        onError: error => {
          captureException(error);
        },
      });

      if (isEmpty(savedViewBreakingChanges)) {
        await updateSubmissionType({
          variables: {
            data: {
              ...omit(data, [
                "inputs",
                "modules",
                "postamble",
                "preamble",
                "notificationEmails",
              ]),
              postamble: getValidPostambleForIntakeSource(data),
              preamble: getValidPreambleForIntakeSource(data),
              formStructure: omit(formStructure, ["isValid", "errors"]),
              submissionTypeId: existingSubmissionType.id,
              submissionTypeVersionId:
                context.selectedVersionId ??
                existingSubmissionType.currentVersion.id,
              notificationEmails: buildNotificationEmailsArray(data),
            },
          },
        });
      } else {
        addErrorToast(
          "Updating this record will break currently saved table views. Modify these views before making changes to the record."
        );
      }
    } else {
      await createSubmissionType({
        variables: {
          data: {
            ...omit(data, [
              "inputs",
              "postamble",
              "preamble",
              "notificationEmails",
            ]),
            postamble: getValidPostambleForIntakeSource(data),
            preamble: getValidPreambleForIntakeSource(data),
            formStructure: omit(formStructure, ["isValid", "errors"]),
            notificationEmails: buildNotificationEmailsArray(data),
          },
        },
      });
    }
  };

  const onRightSidebarClose = () => {
    context.setSelectedVersionId(
      existingSubmissionType?.currentVersion.id ?? null
    );
    setShowRightSidebar(false);
  };

  const rightContainer = (
    <SubmissionsBuilderButtonsWrapper>
      {!showRightSidebar &&
        !!existingSubmissionType &&
        existingSubmissionType.versions.length > 1 &&
        !isDirty && (
          <Icon
            iconName="history"
            color="contentSecondary"
            size={16}
            cursor="pointer"
            testId="version-history"
            onClick={() => {
              setShowRightSidebar(true);
              context.setSelectedVersionId(
                existingSubmissionType.versions[0]!.id
              );
            }}
          />
        )}

      {showRightSidebar && (
        <Button
          styleVariant="outlineLight"
          size="small"
          onClick={onRightSidebarClose}
        >
          Return to current version
        </Button>
      )}
      <Button
        styleVariant={"primary"}
        size="small"
        onClick={handleSubmit(handleSubmitCallback)}
        disabled={!isValid}
      >
        Save
      </Button>
    </SubmissionsBuilderButtonsWrapper>
  );

  return (
    <SubmissionsBuilderContextInstance.Provider value={context}>
      <SupportedMobileVersionsContainer>
        <FormProvider {...formMethods}>
          <FullPageFormLayout
            subtitle={subtitle}
            prevLocation={prevLocation}
            rightContainer={rightContainer}
            middleContainer={<HeaderMiddleContainer />}
            centered={false}
            width="auto"
          >
            <SubmissionsBuilderWrapper hasRightSidebar={showRightSidebar}>
              <SidebarColumn />
              <MainColumn />
              {showRightSidebar && (
                <RightSidebarColumn onClose={onRightSidebarClose} />
              )}
            </SubmissionsBuilderWrapper>
          </FullPageFormLayout>
        </FormProvider>
      </SupportedMobileVersionsContainer>
    </SubmissionsBuilderContextInstance.Provider>
  );
};

const SubmissionBuilderWrapper = () => {
  const { submissionTypeId } = useParams<{ submissionTypeId?: string }>();

  const { data, error, loading } = useGetSubmissionTypeForBuilderQuery({
    fetchPolicy: "cache-and-network",
    variables: { submissionTypeId: submissionTypeId! },
    skip: !submissionTypeId,
  });

  if (error || loading) {
    // data-testid is here since we don't have a way to reliably test this
    // resulting <div /> component in isolation
    return <div data-testid={error ? "Error" : "Loading"}></div>;
  }

  return <SubmissionsBuilder existingSubmissionType={data?.submissionType} />;
};

export default SubmissionBuilderWrapper;
